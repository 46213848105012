import React, { useEffect, useState } from 'react';
import './instacollage.css'; // Import the CSS specific to this component

const InstaCollage = () => {
    useEffect(() => {
        // Load the Instagram embed script
        const script = document.createElement('script');
        script.src = "https://www.instagram.com/embed.js";
        script.async = true;
        document.body.appendChild(script);

        // Cleanup function to remove the script
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    

    const [hoveredPost, setHoveredPost] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredPost(index);
    };

    const handleMouseLeave = () => {
        setHoveredPost(null);
    };

    return (
        <div className="instacollage-container">
            <h2>Swivospace in Action</h2>

            {/* First Row of Posts */}
            <div className="post-container">
                {[
                    "https://www.instagram.com/reel/DAdxkU_O86M/?utm_source=ig_web_copy_link",
                    "https://www.instagram.com/reel/C4quJ2Cvlmh/?utm_source=ig_web_copy_link",
                    "https://www.instagram.com/reel/C2sRPXtOHlX/?utm_source=ig_web_copy_link",
                    "https://www.instagram.com/reel/C3bnf7jMmY9/?utm_source=ig_web_copy_link"
                ].map((link, index) => (
                    <div
                        className="container"
                        key={index}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div className="overlay" style={{ display: hoveredPost === index ? 'flex' : 'none' }}>
                            
                        </div>
                        <blockquote className="instagram-media" data-instgrm-permalink={link} data-instgrm-version="14"></blockquote>
                    </div>
                ))}
            </div>

            
        </div>
    );
};

export default InstaCollage;


